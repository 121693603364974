<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row v-if="Accs">
			<b-col cols="12">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
					{{ FormMSG(215, 'Accomodation') }}
				</h1>
			</b-col>
			<b-col cols="12">
				<transition name="slide">
					<div
						class="details-document-package form"
						:class="{
							'container-layout': $screen.width > 576,
							'container-mobile': $screen.width <= 576,
							'px-0': $screen.width > 576
						}"
					>
						<b-card no-body>
							<b-row class="back-with-title cursor-pointer pt-0 pb-2 pl-0" :class="{ 'mt-16': $isPwa() }">
								<b-col cols="6">
									<button class="btn-transparent" @click="goBack">
										<component :is="getIconLucide('ArrowLeft')" color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
										<span class="title-back-bolder ml-0">
											{{ FormMSG(163, 'Back') }}
										</span>
									</button>
								</b-col>
							</b-row>
							<b-card-text class="px-3 pt-4">
								<b-row v-if="newAcc && newAcc.supplier">
									<b-col cols="12" xl="12">
										<div class="card card-border-blue-light">
											<b-row>
												<b-col sm="12" md="6" lg="6" xl="6">
													<b-form-group :label="FormMSG(11, 'Name')" label-for="accName">
														<b-form-input v-model="newAcc.name" type="text" id="accName"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col sm="12" md="6" lg="6" xl="6">
													<b-form-group :label="FormMSG(51, 'Type')" label-for="accoType">
														<b-form-select
															id="accoType"
															v-model="newAcc.type"
															:disabled="!mapAccoType.length"
															:options="mapAccoType"
														></b-form-select>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row>
												<b-col sm="12">
													<SupplierSelector
														:title="`${
															!isSupplierUpdate ? FormMSG(250, 'Add supplier details') : FormMSG(251, 'Update supplier details')
														}`"
														:label="FormMSG(13, 'Name of the Hotel, Airbnb or Flying company...')"
														:addlabel="FormMSG(30, 'Add')"
														:update-label="FormMSG(27, 'Update')"
														placeholder="Filter by name"
														:supplier-id="supplierId"
														type="hotel"
														ref="refSupplier"
														@change="handleSupplierChange"
														@supplier:unselected="onSupplierUnselected"
													/>
												</b-col>
											</b-row>
											<div class="divider" />
											<div class="sub-title">{{ FormMSG(31, 'Sharing options') }}</div>
											<b-row class="pt-2">
												<b-col sm="12" md="6" lg="6" xl="6">
													<b-row>
														<b-col sm="6" md="6" lg="6" xl="6">
															<b-form-checkbox size="lg" id="toApp" v-model="newAcc.availableOnline" class="pj-cb pb-1">
																{{ FormMSG(12, 'View in App') }}
															</b-form-checkbox>
														</b-col>
														<b-col sm="6" md="6" lg="6" xl="6">
															<b-form-checkbox size="lg" id="toEmail" v-model="newAcc.toEmail" class="pj-cb pb-1">
																{{ FormMSG(112, 'Send Email') }}
															</b-form-checkbox>
														</b-col>
													</b-row>
													<b-row>
														<b-col sm="6" md="6" lg="6" xl="6">
															<b-form-checkbox size="lg" id="toSms" v-model="newAcc.toSms" class="pj-cb pb-1">
																{{ FormMSG(113, 'Send Sms') }}
															</b-form-checkbox>
														</b-col>
														<b-col sm="6" md="6" lg="6" xl="6">
															<b-form-checkbox size="lg" id="toWhatsApp" v-model="newAcc.toWhatsApp" class="pj-cb pb-1">
																{{ FormMSG(114, 'Send WhatsApp') }}
															</b-form-checkbox>
														</b-col>
													</b-row>
												</b-col>
												<b-col sm="12" md="6" lg="6" xl="6">
													<b-form-group :label-cols="0">
														<b-form-textarea
															id="description"
															:placeholder="FormMSG(14, 'description...')"
															:no-resize="true"
															:state="newAcc.description.length < 4000"
															v-model="newAcc.description"
															rows="6"
														></b-form-textarea>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row>
												<b-col sm="12" md="4" lg="4" xl="4" offset-md="8" offset-lg="8" offset-xl="8" class="py-2">
													<b-button @click="curAccUpd()" variant="primary" size="md" :disabled="this.disableSave" block>
														{{ FormMSG(21, 'Save modifications') }}
													</b-button>
												</b-col>
											</b-row>
										</div>
										<div class="card card-border-blue-light">
											<b-row>
												<b-col sm="6">
													<b-form-group>
														<h5>{{ this.lastPublishedOn }}</h5>
													</b-form-group>
												</b-col>
											</b-row>
											<b-card no-body>
												<b-card-header>
													<h4>{{ this.caption4 }}</h4>
												</b-card-header>
												<b-card-body>
													<b-row class="pb-2">
														<b-col sm="12" md="4" lg="4" xl="4" offset-md="8" offset-lg="8" offset-xl="8">
															<b-button
																@click="showDeliveries()"
																variant="success"
																:disabled="newAcc.id == 0"
																size="md"
																class="float-right"
															>
																{{ FormMSG(25, 'Select persons and dates...') }}
															</b-button>
														</b-col>
													</b-row>
													<b-row>
														<b-col cols="12" xl="12" class="hide-on-desktop">
															<CardListBuilder v-if="Dlvs.length > 0" :items="Dlvs" :fields="delifields">
																<template slot="actions" slot-scope="data">
																	<b-button
																		variant="primary"
																		size="sm"
																		v-if="data.item.fileName.length > 0"
																		@click="viewDocument(data.item)"
																		class="btn bg-transparent border-0"
																	>
																		<component :is="getLucideIcon(ICONS.EYE.name)" color="#225CBD" :size="20" />
																	</b-button>
																	<b-button
																		variant="danger"
																		size="sm"
																		@click="removeUserFromDelivery(data.item)"
																		class="btn bg-transparent border-0"
																	>
																		<component
																			:is="getLucideIcon(ICONS.TRASH.name)"
																			:color="ICONS.TRASH.color"
																			:size="20"
																		/>
																	</b-button>
																</template>
															</CardListBuilder>
														</b-col>
														<b-col cols="12" xl="12" class="hide-on-tablet">
															<b-table
																:hover="hover"
																responsive="sm"
																v-if="Dlvs.length > 0"
																ref="myTable"
																sticky-header="700px"
																:head-variant="hv"
																selectable
																:selectedVariant="selectedColor"
																:select-mode="selectMode"
																:items="Dlvs"
																style="text-align: left"
																:fields="delifields"
																:current-page="currentPage"
																:filter="filter"
																:per-page="perPage"
																bordered
																small
															>
																<template #cell(status)="data">
																	<div class="wrap-status">
																		<div :class="`status ${data.item.validatedClass}`" style="font-size: 0.7rem">
																			{{ data.value }}
																		</div>
																	</div>
																</template>
																<template v-slot:cell(view)="data">
																	<b-button
																		variant="primary"
																		size="sm"
																		v-if="data.item.fileName.length > 0"
																		@click="viewDocument(data.item)"
																		class="btn bg-transparent border-0"
																	>
																		<component :is="getLucideIcon(ICONS.EYE.name)" color="#225CBD" :size="20" />
																	</b-button>
																</template>
																<template v-slot:cell(rem)="data">
																	<b-button
																		variant="danger"
																		size="sm"
																		@click="removeUserFromDelivery(data.item)"
																		class="btn bg-transparent border-0"
																	>
																		<component
																			:is="getLucideIcon(ICONS.TRASH.name)"
																			:color="ICONS.TRASH.color"
																			:size="20"
																		/>
																	</b-button>
																</template>
															</b-table>
														</b-col>
													</b-row>
												</b-card-body>
											</b-card>
										</div>
									</b-col>
								</b-row>
							</b-card-text>
						</b-card>
					</div>
				</transition>
			</b-col>
		</b-row>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(22, 'Success!')"
			class="modal-success"
			v-model="successModal"
			@ok="successModal = false"
			ok-variant="success"
			ok-only
		>
			{{ this.FormMSG(23, 'The new accomodation has been saved.') }}
		</b-modal>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(22, 'Success!')"
			class="modal-success"
			v-model="successModalDelete"
			@ok="successModalDelete = false"
			ok-variant="success"
			ok-only
		>
			{{ this.FormMSG(24, 'This accomodation has been deleted.') }}
		</b-modal>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import { isNil, omit } from '@/shared/utils';
import SupplierSelector from '@/components/SupplierSelector';
import languageMessages from '@/mixins/languageMessages';
import * as icons from 'lucide-vue';
import { classDocumentStatus } from '@/shared/global-status';
import GlobalMixin from '@/mixins/global.mixin';

const query_deliveries = gql`
	query ($AccomodationId: ID!) {
		GetAccomodationDeliveryList(AccomodationId: $AccomodationId) {
			id
			user {
				id
				name
				firstName
				email
				departmentName
				functionName
				department
			}
			status
			startDate
			endDate
			fileName
			confirmation
		}
	}
`;

const query_accomodations = gql`
	query ($AccomodationId: ID!) {
		GetAccomodationWithId(AccomodationId: $AccomodationId) {
			id
			name
			type
			description
			body
			supplierId
			pricePerNight
			cityTax
			vatAmount
			comment
			accessMap
			publishedOn
			publisherId
			toEmail
			toSms
			toWhatsApp
			availableOnline
			publisher {
				name
				firstName
			}
			supplierId
			supplier {
				id
				addressId
				name
				email
				phone
				vat
				contactName
				address {
					id
					state
					street
					number
					box
					lat
					lng
					city
					country
					countryCode
					countryKey
					zip
				}
			}
		}
	}
`;

const mutation_removeUserFromDelivery = gql`
	mutation ($AccomodationId: ID!, $UserId: ID!, $StrDate: String, $EndDate: String, $AccomodationDeliveryId: Int) {
		RemoveUserFromDeliveriesForAccomodation(
			AccomodationId: $AccomodationId
			UserId: $UserId
			StrDate: $StrDate
			EndDate: $EndDate
			AccomodationDeliveryId: $AccomodationDeliveryId
		) {
			user {
				id
				name
				firstName
				email
				departmentName
				functionName
				department
			}
			status
			selected
			startDate
			endDate
			fileName
			confirmation
		}
	}
`;

const mutation_curAccUpd = gql`
	mutation ($AccomodationId: ID!, $UpdatedAccomodation: AccomodationInput!) {
		UpdAccomodation(AccomodationId: $AccomodationId, UpdatedAccomodation: $UpdatedAccomodation) {
			id
			name
			type
			description
			body
			supplierId
			pricePerNight
			cityTax
			vatAmount
			comment
			accessMap
			publishedOn
			publisherId
			toEmail
			toSms
			toWhatsApp
			availableOnline
			publisher {
				name
				firstName
			}
			supplierId
			supplier {
				id
				addressId
				name
				email
				phone
				vat
				contactName
				address {
					id
					state
					street
					number
					box
					lat
					lng
					city
					country
					countryCode
					countryKey
					zip
				}
			}
		}
	}
`;

// const mutation_curAccDel = gql`
// 	mutation ($AccomodationId: ID!) {
// 		DelAccomodation(AccomodationId: $AccomodationId) {
// 			id
// 		}
// 	}
// `

export default {
	name: 'Accomodations',

	components: {
		Loading,
		SupplierSelector
	},
	mixins: [languageMessages, GlobalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			formDataObj: {},
			Accs: [],
			Dlvs: [],
			curAcc: {},
			newAcc: {},
			currentPage: 1,
			currentIdx: 0,
			stopPropagation: '',
			nativeEvent: '',
			perPage: 0,
			warning: '',
			filter: '',
			isLoading: false,
			selectMode: 'single',
			selectedColor: 'primary',
			successModal: false,
			successModalDelete: false,
			firstTime: true,
			lastPublishedOn: '',
			editData: {
				type: Object,
				required: false,
				default: null
			},
			isSupplierUpdate: false
		};
	},
	computed: {
		caption4() {
			return this.FormMSG(26, 'Persons and periods selected');
		},
		mapAccoType() {
			return this.FormMenu(1131);
		},
		delifields() {
			return [
				{
					key: 'fullName',
					label: this.FormMSG(1, 'Name'),
					sortable: true
				},
				{
					key: 'user.departmentName',
					label: this.FormMSG(2, 'Department'),

					sortable: true
				},
				{
					key: 'user.functionName',
					label: this.FormMSG(3, 'Function'),
					sortable: true
				},
				{
					key: 'startDate',
					label: this.FormMSG(4, 'From'),
					sortable: true,
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					}
				},
				{
					key: 'endDate',
					label: this.FormMSG(5, 'To'),
					sortable: true,
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					}
				},
				{
					key: 'status',
					label: this.FormMSG(106, 'Status'),
					formatter: (value) => {
						if (value == 0) {
							return this.FormMSG(120, 'Not published');
						}
						if (value == 1) {
							return this.FormMSG(121, 'Not received yet');
						}
						if (value == 2) {
							return this.FormMSG(122, 'Received (not read)');
						}
						if (value == 3) {
							return this.FormMSG(123, 'Read');
						}
						if (value == 4) {
							return this.FormMSG(124, 'Signed');
						}
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'fileName',
					label: this.FormMSG(6, 'File name'),
					sortable: true
				},
				{
					key: 'view',
					label: this.FormMSG(8, 'View'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'rem',
					label: this.FormMSG(7, 'Delete'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		showMessageBody() {
			//console.log("in showMessageBody ");
			return this.newAcc.toEmail;
		},
		disableSave() {
			if (
				this.newAcc.name != this.curAcc.name ||
				this.newAcc.availableOnline != this.curAcc.availableOnline ||
				this.newAcc.type != this.curAcc.type ||
				this.newAcc.toEmail != this.curAcc.toEmail ||
				this.newAcc.toSms != this.curAcc.toSms ||
				this.newAcc.toWhatsApp != this.curAcc.toWhatsApp ||
				this.newAcc.description != this.curAcc.description ||
				this.newAcc.supplierId != this.curAcc.supplierId
			) {
				return false;
			}
			return true;
		},
		caption1() {
			var newCaption = this.FormMSG(10, 'Accomodation:') + ' ' + this.newAcc.name;
			return newCaption;
		},
		supplierId() {
			// current supplier id of edit data
			this.isSupplierUpdate = true;

			if (isNil(this.newAcc) || isNil(this.newAcc.supplier) || parseInt(this.newAcc.supplier.id, 10) === 0) {
				this.isSupplierUpdate = false;

				return 0;
			}

			return this.newAcc.supplier.id;
		}
	},
	methods: {
		fillDlvs() {
			for (let i = 0; i < this.Dlvs.length; i++) {
				this.Dlvs[i].selected = 0;
				if (
					(!this.Dlvs[i].user.name && !this.Dlvs[i].user.firstName) ||
					(this.Dlvs[i].user.name.length === 0 && this.Dlvs[i].user.firstName.length === 0)
				) {
					this.Dlvs[i].fullName = this.Dlvs[i].user.email;
				} else {
					this.Dlvs[i].fullName = this.Dlvs[i].user.name + ' ' + this.Dlvs[i].user.firstName;
				}

				this.Dlvs[i].validatedClass = classDocumentStatus(this.Dlvs[i].status);
			}
		},
		reloadDlvsData() {
			//fetchPolicy: 'no-cache'
			var AccomodationId = parseInt(this.newAcc.id, 10);
			this.$apollo
				.query({
					query: query_deliveries,
					variables: {
						AccomodationId
					},
					// options: { fetchPolicy: 'no-cache' },
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					this.Dlvs = result.data.GetAccomodationDeliveryList;
					//console.log("reload data dlvs:", result);
					this.fillDlvs();
					//console.log("dlvs:", this.Dlvs);
				})
				.catch((error) => {
					// console.log(error)
					this.erreur = error;
				});
		},
		handleSupplierChange(supplier) {
			this.isSupplierUpdate = false;
			//console.log("SupplierSelector:", supplier);
			if (isNil(supplier)) return;

			this.isSupplierUpdate = true;
			const { id, supplierName } = supplier;
			//console.log("SupplierSelector id:", id);
			this.newAcc.supplierId = parseInt(id);
			this.newAcc.supplier.name = supplierName;
			this.newAcc.supplier.id = this.newAcc.supplierId;
			//console.log("handleSupplierChange this.newAcc:", this.newAcc);
		},
		setPublishedDate() {
			if (this.alreadyPublished()) {
				const date = moment(this.newAcc.publishedOn).format('DD/MM/YYYY HH:mm');
				return this.FormMSG(501, 'Created by:') + ' ' + this.newAcc.publisher.name + ' ' + this.newAcc.publisher.firstName + '    - ' + date;
			} else {
				return 'NOT PUBLISHED YET';
			}
		},
		alreadyPublished() {
			var y = new Date('2013-05-23');
			var x = new Date(this.newAcc.publishedOn.substring(0, 10));
			if (x.getTime() > y.getTime()) {
				return true;
			} else {
				return false;
			}
		},
		viewDocument(item) {
			if (item.confirmation.length > 0) {
				var urlStr = process.env.VUE_APP_GQL + '/images/' + item.confirmation;
			}
			window.open(urlStr); // in a new window

			return;
		},
		showDeliveries() {
			// copy item content into curTs structure.
			//console.log("this.newAcc:", this.newAcc);
			store.state.curAccomodation = this.newAcc;
			const deliveryLink = `/accomodationList/distribution/${this.newAcc.id.toString()} `;
			//console.log("starting:", deliveryLink);
			this.$router.push({
				path: deliveryLink
			});
		},
		fillUpdatedAccomodation() {
			var UpdatedAccomodation = {
				...this.newAcc
			};
			//console.log("UpdatedAccomodation:", UpdatedAccomodation)
			return UpdatedAccomodation;
		},
		curAccUpd() {
			// loading variables from newAcc
			//console.log("before call to update Doc, this.newAcc:", this.newAcc);
			const UpdatedAccomodation = this.fillUpdatedAccomodation();
			const AccomodationId = parseInt(this.newAcc.id, 10);

			// console.log('UpdatedAccomodation1:', UpdatedAccomodation)
			this.$apollo
				.mutate({
					mutation: mutation_curAccUpd,
					variables: {
						AccomodationId,
						UpdatedAccomodation: omit(['supplier'], UpdatedAccomodation)
					}
				})
				.then((result) => {
					this.curAcc = Object.assign({}, result.data.UpdAccomodation);
					this.newAcc = Object.assign({}, result.data.UpdAccomodation);
					// find object in the list and update accordingly
					//console.log("after acc update result:", result);
					//console.log("after acc update newAcc:", this.newAcc);
					for (let i = 0; i < this.Accs.length; i++) {
						// console.log({ 'this.Accs[i]': this.Accs[i] })
						if (this.Accs[i].id == result.data.UpdAccomodation.id) {
							this.Accs[i].name = this.curAcc.name;
							this.Accs[i].fileName = this.curAcc.fileName;
							/*
                                    this.Accs[i] = this.curAcc;
                                    this.Accs[i] = {
                                        ...this.Accs[i],
                                            ...this.curAcc
                                    }; */
							//console.log("after doc update this.Accs[i]:", this.Accs[i]);
							break;
						}
					}
					this.successModal = true;
				})
				.catch((error) => {
					console.log(error);
					//this.erreur = error
					var myerrorObject = {
						err: ''
					};
					myerrorObject.err = error.message;
					this.erreur = myerrorObject;
				});
		},
		initValuesForNoRecords() {
			this.newAcc.fullName = 'None';
			this.newAcc.name = ' NEW ACCOMODATION';
			this.newAcc.supplier = {};
			this.newAcc.supplier.name = 'Hotel or Ticket...';
			this.newAcc.toEmail = false;
			this.newAcc.toSms = false;
			this.newAcc.toWhatsApp = false;
			this.newAcc.availableOnline = true;
			this.newAcc.type = 290;
			this.newAcc.body = '';
			this.newAcc.id = 0;
			this.newAcc.description = '';
			this.newAcc.supplierId = 0;
			this.newAcc.pricePerNight = 0;
			this.newAcc.cityTax = 0;
			this.newAcc.vatAmount = 0;
			this.newAcc.comment = '';
			this.newAcc.accessMap = '';
			this.newAcc.publishedOn = '';
			this.newAcc.publisherId = 0;
			this.newAcc.publisher = {};
			this.newAcc.publisher.name = '';
			this.newAcc.publisher.firstName = '';
			this.newAcc.supplierId = 0;
			this.newAcc.supplier.addressId = 0;
			this.newAcc.supplier.name = '';
			this.newAcc.supplier.email = '';
			this.newAcc.supplier.phone = '';
			this.newAcc.supplier.vat = '';
			this.newAcc.supplier.contactName = '';
			this.newAcc.supplier.address = {};
			this.newAcc.supplier.address.street = '';
			this.newAcc.supplier.address.number = '';
			this.newAcc.supplier.address.zip = '';
			this.newAcc.supplier.address.state = '';
			this.newAcc.supplier.address.country = '';
			this.newAcc.supplier.address.city = '';
			this.newAcc.supplier.address.lat = 0;
			this.newAcc.supplier.address.lng = 0;
		},
		reloadData() {
			var AccomodationId = 0;
			if (!isNil(store.state.curAccomodation)) {
				AccomodationId = parseInt(store.state.curAccomodation.id, 10);
			} else {
				AccomodationId = parseInt(this.$route.params.id, 10);
			}
			//console.log("accomodationId:", AccomodationId);
			this.$apollo
				.query({
					query: query_accomodations,
					variables: {
						AccomodationId
					},
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					//console.log("result:", result);
					this.Accs = result.data.GetAccomodationWithId;
					//console.log("this.Accs:", this.Accs);
					for (var i = 0; i < this.Accs.length; i++) {
						this.Accs[i].fullName = this.Accs[i].publisher.name + ' ' + this.Accs[i].publisher.firstName;
					}
					// select element
					if (this.Accs.length > 0) {
						this.curAcc = this.Accs[0];
						this.newAcc = Object.assign({}, this.curAcc);

						var idx = store.getCurrentAccomodationTableIndex();
						//console.log("var idx = store.state.getCurrentAccomodationTableIndex", idx);
						this.lastPublishedOn = this.setPublishedDate();
						this.firstTime = false;
					} else {
						// console.log('in reload data initValuesForNoRecords')
						this.initValuesForNoRecords();
						// console.log('end initValuesForNoRecords:', this.newAcc)
					}

					//console.log("Accs:", this.Accs);
					this.$nextTick(function () {
						// updating supplier component
						var selsup = {
							id: this.curAcc.supplierId,
							name: this.curAcc.supplier.name
						};
						//console.log("selsup:", this.$refs.refSupplier);
						this.$refs.refSupplier.setSelectedSupplier(selsup);
						// end updating supplier component
					});
					this.reloadDlvsData();
				})
				.catch((error) => {
					// console.log('in reload data:', error)
					this.erreur = error;
				});
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		rowClicked(item, index) {
			this.currentIdx = (this.currentPage - 1) * this.perPage + index;

			store.setCurrentAccomodationTableIndex(this.currentIdx);

			this.curAcc = item;

			// updating supplier component
			var selsup = {
				id: this.curAcc.supplierId,
				name: this.curAcc.supplier.name
			};
			this.$refs.refSupplier.setSelectedSupplier(selsup);
			// end updating supplier component

			this.newAcc = Object.assign({}, item);
			this.lastPublishedOn = this.setPublishedDate();

			this.$refs['pdfConfirmationFileId'].reset();
			this.reloadDlvsData();
		},
		removeUserFromDelivery(item) {
			var UserId = parseInt(item.user.id, 10);
			// console.log("1in removeUserFromDelivery UserId:", UserId);
			var AccomodationId = parseInt(this.newAcc.id, 10);
			// console.log("2in removeUserFromDelivery AccomodationId:", AccomodationId);
			var AccomodationDeliveryId = parseInt(item.id);
			var StrDate,
				EndDate = '';
			// format 2020-08-06T00:00:00Z
			StrDate = item.startDate.substring(0, 10) + 'T00:00:00'; // + this.timeZoneStr;
			// console.log('in STRING removeUserFromDelivery StrDate:', StrDate)
			EndDate = item.endDate.substring(0, 10) + 'T00:00:00'; // + this.timeZoneStr;
			// console.log('in STRING removeUserFromDelivery EndDate:', EndDate)
			this.$apollo
				.mutate({
					mutation: mutation_removeUserFromDelivery,
					variables: {
						AccomodationId,
						UserId,
						StrDate,
						EndDate,
						AccomodationDeliveryId
					}
				})
				.then((result) => {
					this.reloadDlvsData();
					//this.Dlvs = result.data.RemoveUserFromDeliveriesForAccomodation;
					//this.fillDlvs();
					//this.successModalDelete = true;
				})
				.catch((error) => {
					// console.log(error)
					this.erreur = error;
				});
		},
		goBack() {
			this.$router.go(-1);
		},
		onSupplierUnselected() {
			this.isSupplierUpdate = false;
		},
		getIconLucide(name) {
			return icons[name];
		}
	},
	created() {
		this.reloadData();
	}
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}
</style>
